/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useCallback, useEffect, useState } from 'react';

import AudioApi from '@phoenix7dev/audio-api';
import { formatNumber } from '@phoenix7dev/utils-fe';

import { ISongs, config } from '../../config';
import { IReplayBet, ISettledBet } from '../../global.d';
import { PlaceBetInput } from '../../gql/__generated__/graphql';
import {
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setFreeRoundBonus,
  setGameMode,
  setIsAutoSpins,
  setIsBuyFeaturePopupOpened,
  setIsBuyFeatureSpin,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
  setIsOpeningScenePlaying,
  setIsPopupFreeRoundsOpened,
  setIsRespinRevivalWaiting,
  setIsRevokeThrowingError,
  setIsSlotBusy,
  setIsSpinInProgress,
  setIsSpinShortCut,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setProgress,
  setReplayBet,
  setReplayFreeSpinBets,
  setRespinCnt,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setStressful,
  setWinAmount,
} from '../../gql/cache';
import { placeBetGql } from '../../gql/mutation';
import { configGql, getAutoSpinsGql, getBetAmountGql, getUserGql, isStoppedGql, replayBetGql } from '../../gql/query';
import SlotMachine from '../../slotMachine';
import { EventTypes, SlotMachineState, eventManager } from '../../slotMachine/config';
import {
  canPressSpin,
  isBaseGameMode,
  isFreeSpinsMode,
  saveReelPosition,
  showCurrency,
  updateLastBet,
} from '../../utils';

const Spin: React.FC = () => {
  const { data } = useQuery(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery(getBetAmountGql);
  const { id: slotId } = useReactiveVar(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar(setIsFreeSpinsWin);
  const { data: userData } = useQuery(getUserGql);
  const progress = useReactiveVar(setProgress);
  const { data: dataSlotStopped } = useQuery(isStoppedGql);

  const gameMode = useReactiveVar(setGameMode);
  const balanceAmount = userData?.user.balance?.amount || 0;
  const [winThreeTimes, setGameHistory] = useState([false, false, false, false, false]);

  const { data: autoSpins } = useQuery(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const stressful = useReactiveVar(setStressful);

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isFreeSpinsMode(setGameMode()) && !setCurrentBonus().isActive;

  const betCompleteCallback = (placeBet: ISettledBet | IReplayBet, _client: ApolloClient<unknown>): void => {
    window.dispatchEvent(new CustomEvent('placeBetCompleted'));
    const clonedPlaceBet = JSON.parse(JSON.stringify(placeBet)) as ISettledBet | IReplayBet;
    if (clonedPlaceBet?.rewards) {
      const replayBonusIndex = clonedPlaceBet.rewards.findIndex((reward) => reward?.__typename === 'ReplayBonusReward');
      if (replayBonusIndex > -1) {
        clonedPlaceBet.rewards[replayBonusIndex]!.__typename = 'BetBonusReward';
        clonedPlaceBet.bet.userBonus = {
          bonusId: clonedPlaceBet.bet.data.bonuses[0]?.bonusId!,
        };
      }
    }
    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData!.user,
          balance: clonedPlaceBet!.balance.placed,
        },
      },
    });
    SlotMachine.getInstance().setResult(clonedPlaceBet as ISettledBet);
    updateLastBet(clonedPlaceBet as ISettledBet);
    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    const callBack = () => {
      const win = clonedPlaceBet!.bet.result.winCoinAmount;
      const lastThreeSpins = [...winThreeTimes.slice(1), !!win];
      setGameHistory(lastThreeSpins);

      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData!.user,
            balance: clonedPlaceBet!.balance.settled,
          },
        },
      });
      saveReelPosition(clonedPlaceBet!.bet.result.reelPositions as number[]);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const [fnGet, { client }] = useMutation(placeBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
    onError() {
      window.dispatchEvent(new CustomEvent('placeBetCompleted'));
    },
  });

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE) {
        if (isFreeSpinsMode(setGameMode())) return;
        eventManager.emit(
          EventTypes.UPDATE_WIN_VALUE,
          formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
        );
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });

        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          const variables = {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: setSlotConfig().lineSet.id,
            } as PlaceBetInput,
          };
          const freeRoundBonus = setFreeRoundBonus() && setFreeRoundBonus().isActive;
          if (freeRoundBonus) {
            variables.input.userBonusId = setFreeRoundBonus().id;
          }
          fnGet({ variables });
        }

        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.XT003S_spin_start });
        AudioApi.play({ type: ISongs.XT003S_spin_loop, stopPrev: true, loop: true });
      } else {
        if (SlotMachine.getInstance().state === SlotMachineState.STOP && !setIsAutoSpins()) {
          client.writeQuery({
            query: isStoppedGql,
            data: {
              isSlotStopped: true,
            },
          });
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, slotId],
  );

  useEffect(() => {
    const buyFeature = (id: string) => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      setIsBuyFeatureSpin(true);
      SlotMachine.getInstance().spin(isTurboSpin);
      eventManager.emit(
        EventTypes.UPDATE_WIN_VALUE,
        formatNumber({ currency: setCurrency(), value: 0, showCurrency: showCurrency(setCurrency()) }),
      );
      setWinAmount(0);
      setLastRegularWinAmount(0);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayBet()) {
        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: setSlotConfig().lineSet.id,
              userBonusId: id,
            },
          },
        });
      }
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.XT003S_spin_start });
    };

    eventManager.on(EventTypes.START_BUY_FEATURE_ROUND, buyFeature);
    return () => {
      eventManager.removeListener(EventTypes.START_BUY_FEATURE_ROUND, buyFeature);
    };
  }, [onSpin, isTurboSpin]);

  useEffect(() => {
    const freeSpin = () => {
      if (setIsTimeoutErrorMessage() || setIsRevokeThrowingError()) return;
      SlotMachine.getInstance().spin(isTurboSpin);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: false,
        },
      });
      if (setReplayBet()) {
        const replayFreeSpins = setReplayFreeSpinBets();
        if (replayFreeSpins.length) {
          setReplayBet(replayFreeSpins[setRespinCnt() - 1]);
        }
        getReplayBet({
          variables: { betId: setReplayBet() },
        });
      } else {
        fnGet({
          variables: {
            input: {
              slotId,
              coinAmount: setCoinAmount(),
              coinValue: setCoinValue(),
              lineSetId: setSlotConfig().lineSet.id,
              userBonusId: setCurrentBonus().id,
            },
          },
        });
      }
      setIsSpinInProgress(true);
      setIsSlotBusy(true);
      AudioApi.play({ type: ISongs.XT003S_spin_start });
    };
    eventManager.on(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    return () => {
      eventManager.removeListener(EventTypes.NEXT_FREE_SPINS_ROUND, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      const popupFreeRoundsOpened = setIsPopupFreeRoundsOpened();

      if (
        autoSpinsLeft ||
        bonus ||
        stopOnWin ||
        stopOnWinExceeds ||
        balanceIncrease ||
        balanceDecrease ||
        popupFreeRoundsOpened
      ) {
        setIsAutoSpins(false);
        if (!setIsSlotBusy()) {
          eventManager.emit(EventTypes.DISABLE_BUY_FEATURE_BTN, false);
        }
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (isBaseGameMode(setGameMode()) && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (isAutoSpins) {
      checkAutoSpinSettings();
      return;
    }
    onSpin(isTurboSpin);
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (stressful.show) return;

        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isInTransition: setIsInTransition(),
            isBuyFeaturePopupOpened: setIsBuyFeaturePopupOpened(),
            isOpeningScenePlaying: setIsOpeningScenePlaying(),
            isSpinShortCut: setIsSpinShortCut(),
            isRespinRevivalWaiting: setIsRespinRevivalWaiting(),
            isAutoPlay: false,
          }) ||
          setIsPopupFreeRoundsOpened()
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (progress.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          onSpin(isTurboSpin);
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      progress.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
      stressful,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {}, [winThreeTimes]);

  useEffect(() => {
    let id: number;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      id = window.setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : config.autoplay.timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick]);

  return null;
};

export default Spin;
